import { TalkingHead as SpeakingAvatar } from "./speakingavatar.js";
import { lipsyncGetProcessor } from './speakingavatar.js';

let avatar;
let recognition;
let audioContext;
let isListening = false;
let isWaitingForResponse = false;
let userInitiatedSpeech = false;
let isProcessingResponse = false;
let isSpeaking = false;
let googleApiKey; // Google API Key

// Function to fetch Google API key from the server
async function fetchGoogleApiKey() {
  try {
    const response = await fetch('https://my-server-app-production.up.railway.app/googlekey'); // Replace with your server's URL
    const data = await response.json();
    googleApiKey = data.googleApiKey;
  } catch (error) {
    console.error('Error fetching Google API Key:', error);
  }
}

// Initialize AudioContext and resume it after user gesture
function initAudioContext() {
  if (!audioContext) {
    audioContext = new (window.AudioContext || window.webkitAudioContext)();
  }

  // Resume the AudioContext after user gesture (click)
  if (audioContext.state === 'suspended') {
    document.getElementById('start-conversation').addEventListener('click', async () => {
      await audioContext.resume();
      console.log('AudioContext resumed');
    }, { once: true });
  }

  return audioContext;
}

// Initialize speech recognition
function initSpeechRecognition() {
  recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
  recognition.continuous = false;
  recognition.interimResults = false;
  recognition.lang = 'en-US';

  recognition.onresult = function(event) {
    console.log('Speech recognition result:', event.results[0][0].transcript);
    const finalTranscript = event.results[0][0].transcript;
    document.getElementById('text').value += 'You: ' + finalTranscript + '\n';
    processAIResponse(finalTranscript);
  };

  recognition.onend = function() {
    console.log('Speech recognition ended.');
    isListening = false;
    document.getElementById('start-conversation').textContent = 'Start conversation';
    document.getElementById('start-conversation').disabled = false;
  };

  recognition.onerror = function(event) {
    console.error('Speech recognition error:', event.error);
    isListening = false;
    document.getElementById('start-conversation').textContent = 'Start conversation';
    document.getElementById('start-conversation').disabled = false;
  };
}

// Process AI response after receiving user input
async function processAIResponse(userInput) {
  if (isWaitingForResponse || !userInitiatedSpeech || isProcessingResponse) return;

  const statusElement = document.getElementById('ai-status');
  try {
    isProcessingResponse = true;
    isWaitingForResponse = true;
    statusElement.textContent = 'AI is thinking...';
    statusElement.style.display = 'block';

    const response = await fetch('https://my-server-app-production.up.railway.app/chat', { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ messages: [{ role: "user", content: userInput }] }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    document.getElementById('text').value += 'AI: ' + data.response + '\n';
    statusElement.textContent = 'AI is speaking...';
    await speak(data.response);
  } catch (error) {
    console.error('Error processing AI response:', error);
    document.getElementById('text').value += "Error: Couldn't process the request. Please try again.\n";
  } finally {
    statusElement.style.display = 'none';
    isWaitingForResponse = false;
    isProcessingResponse = false;
    document.getElementById('start-conversation').textContent = 'Start conversation';
    document.getElementById('start-conversation').disabled = false;
  }
}

// Function to handle text-to-speech
async function speak(text) {
  if (text && userInitiatedSpeech) {
    isSpeaking = true;
    document.getElementById('start-conversation').textContent = 'AI speaking...';
    document.getElementById('start-conversation').disabled = true;

    if (audioContext.state === 'suspended') {
      await audioContext.resume();
    }

    return new Promise((resolve) => {
      avatar.speakText(text);
      setTimeout(() => {
        isSpeaking = false;
        resolve();
      }, text.length * 100); // Rough estimate: 100ms per character
    });
  }
}

// Start the conversation
function startConversation() {
  if (!isListening && !isSpeaking && !isProcessingResponse) {
    isListening = true;
    userInitiatedSpeech = true;

    console.log('Starting conversation...');

    if (audioContext.state === 'suspended') {
      console.log('Resuming AudioContext...');
      audioContext.resume();
    }

    recognition.start();
    document.getElementById('start-conversation').textContent = 'Listening...';
    document.getElementById('start-conversation').disabled = true;
  } else {
    console.log('Conversation already started or another process is in progress.');
  }
}

// Stop the conversation
function stopConversation() {
  if (isListening) {
    recognition.stop();
  }
  if (avatar && avatar.stopSpeaking) {
    avatar.stopSpeaking();
  }
  isListening = false;
  isSpeaking = false;
  userInitiatedSpeech = false;
  isProcessingResponse = false;
  document.getElementById('start-conversation').textContent = 'Start conversation';
  document.getElementById('start-conversation').disabled = false;
  document.getElementById('ai-status').style.display = 'none';
}

// Reset the conversation
function resetConversation() {
  stopConversation();
  document.getElementById('text').value = '';
  isWaitingForResponse = false;
}

// Main initialization logic
document.addEventListener('DOMContentLoaded', async function() {
  initSpeechRecognition();
  audioContext = initAudioContext();

  // Fetch Google API key on page load
  await fetchGoogleApiKey();

  const nodeAvatar = document.getElementById('avatar');
  avatar = new SpeakingAvatar(nodeAvatar, {
    ttsEndpoint: "https://eu-texttospeech.googleapis.com/v1beta1/text:synthesize",
    ttsApikey: googleApiKey,
    lipsyncModules: ["en"],
    cameraView: "head",
    audioContext: audioContext
  });

  const statusElement = document.getElementById('ai-status');
  try {
    statusElement.textContent = "Preparing your virtual assistant...";
    statusElement.style.display = 'block';
    await avatar.showAvatar({
      url: 'https://models.readyplayer.me/66cf61e593aa91992c4a1bd9.glb?morphTargets=ARKit,Oculus+Visemes,mouthOpen,mouthSmile,eyesClosed,eyesLookUp,eyesLookDown&textureSizeLimit=1024&textureFormat=png',
      body: 'F',
      avatarMood: 'neutral',
      ttsLang: "en-US",
      ttsVoice: "en-US-Standard-C",
      lipsyncLang: 'en'
    }, (ev) => {
      if (ev.lengthComputable) {
        let val = Math.min(100, Math.round(ev.loaded / ev.total * 100));
        statusElement.textContent = `Preparing your virtual assistant: ${val}%`;
      }
    });
    statusElement.style.display = 'none';
  } catch (error) {
    console.error(error);
    statusElement.textContent = "Unable to load virtual assistant. Please try again.";
  }

  document.getElementById('start-conversation').addEventListener('click', startConversation);
  document.getElementById('stop').addEventListener('click', stopConversation);
  document.getElementById('reset').addEventListener('click', resetConversation);
});
